import { Box, Button, Text, VStack, useDisclosure } from "@chakra-ui/react";
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
import BasketIcon from "assets/icons/BasketIcon";
import PortfolioIcon from "assets/icons/PortfolioIcon";
import SubscriptionIcon from "assets/icons/SubscriptionIcon";
import Trade from "assets/icons/Trade";
import UserIcon from "assets/icons/UserIcon";
import AddClient from "components/modals/AddClient";
import CSVUpload from "components/modals/CSVUpload";
import CreateStrategy from "components/modals/CreateStrategy";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleModal } from "app/features/modal";
import theme from "./utils/theme";
import MarketPlace from "assets/icons/MarketPlace";
import { MdDashboard } from "react-icons/md";
const Sidebar = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverOpen2, setIsPopoverOpen2] = useState(false);
  const [isPopoverOpen3, setIsPopoverOpen3] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const {
    isOpen: isOpenUpload,
    onOpen: onOpenUpload,
    onClose: onCloseUpload,
  } = useDisclosure();
  const brand = theme.colors.brand[600];
  const [isHovered0, setIsHovered0] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [isHovered2, setIsHovered2] = React.useState(false);
  const [isHovered3, setIsHovered3] = React.useState(false);
  const [isHovered4, setIsHovered4] = React.useState(false);
  const [isHovered5, setIsHovered5] = React.useState(false);

  if (location.pathname == "/") {
    return null;
  }
  const closePopover = (popoverNumber) => {
    switch (popoverNumber) {
      case 1:
        setIsPopoverOpen(false);
        break;
      case 2:
        setIsPopoverOpen2(false);
        break;
      case 3:
        setIsPopoverOpen3(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <VStack ml="2" pt="2"  width="7%" height={"100vh"} gap="1%" bg="white">
        <Box
          w="100%"
          h="65px"
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
          cursor={"pointer"}
          onClick={() => {
            // navigate("/Market-Place");
            navigate("/dashboard")
            window.location.reload()
          }}
          borderRadius={isHovered0 ? "10px" : ""}
          color={isHovered0 ? brand : brand}
          bg={isHovered0 ? "brand.900" : ""}
          onMouseOver={() => setIsHovered0(true)}
          onMouseOut={() => setIsHovered0(false)}
        >
          {" "}
          {/* <MarketPlace /> */}
          <MdDashboard fontSize={"27px"} />

          <Text
            borderColor="transparent"
            fontWeight="700"
            fontSize="13px"
            fontStyle="normal"
            lineHeight="normal"
          >
           Dashboard
          </Text>
        </Box>
        <Box
          w="100%"
          h="65px"
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
          cursor={"pointer"}
          onClick={() => {
            navigate("/order-placement");
            
          }}
          borderRadius={isHovered ? "10px" : ""}
          color={isHovered ? brand : brand}
          bg={isHovered ? "brand.900" : ""}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          {" "}
          <Trade
            color={isHovered ? brand : brand}
            height={"27px"}
            width="27px"
          />
          <Text
            borderColor="transparent"
            fontWeight="700"
            fontSize="13px"
            fontStyle="normal"
            lineHeight="normal"
          >
            Execution
          </Text>
        </Box>
        {/* <Box
          borderRadius={isHovered2 ? "10px" : ""}
          color={isHovered2 ? brand : brand}
          bg={isHovered2 ? "brand.900" : ""}
          onMouseOver={() => setIsHovered2(true)}
          onMouseOut={() => setIsHovered2(false)}
          w="100%"
          h="65px"
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
          cursor={"pointer"}
          onClick={() => navigate("/SubscriptionTabs")}
        >
          <SubscriptionIcon
            color={isHovered2 ? brand : brand}
            height={"27px"}
            width="27px"
          />
          <Text
            borderColor="transparent"
            fontWeight="700"
            fontSize="13px"
            fontStyle="normal"
          >
            Subscription
          </Text>
        </Box> */}
        {/* <Box
        w="100%"
        h="65px"
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
        cursor={"pointer"}
        borderRadius={isHovered3 ? "10px" :''}
        color={isHovered3?"white": brand}
        bg={isHovered3 ? brand:""}
        onMouseOver={() => setIsHovered3(true)}
      onMouseOut={() => setIsHovered3(false)}
    
      > */}
        <Popover
          placement="right"
          onClose={() => closePopover(3)}
          isOpen={isPopoverOpen3}
          zIndex={18}
        >
          <PopoverTrigger>
            {/* <IconButton
              icon={}
              aria-label="Open menu"
              // variant="unstyled"bg=""
             
              onClick={() => setIsPopoverOpen3(!isPopoverOpen3)}
            /> */}
            {/* <span onClick={() => setIsPopoverOpen3(!isPopoverOpen3)}>

</span> */}

            <Box
              w="100%"
              h="65px"
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              justifyContent={"center"}
              cursor={"pointer"}
              borderRadius={isHovered3 ? "10px" : ""}
              color={isHovered3 ? brand : brand}
              bg={isHovered3 ? "brand.900" : ""}
              onMouseOver={() => setIsHovered3(true)}
              onMouseOut={() => setIsHovered3(false)}
              // onClick={() => setIsPopoverOpen3(!isPopoverOpen3)}
              onClick={() => setIsPopoverOpen3((prevState) => !prevState)}
            >
              <UserIcon
                color={isHovered3 ? brand : brand}
                height={"27px"}
                width="27px"
              />

              <Text
                borderColor="transparent"
                fontWeight="700"
                fontSize="13px"
                fontStyle="normal"
                mt="1"
              >
                Clients
              </Text>
            </Box>
          </PopoverTrigger>

          <PopoverContent w="150px" bg="white">
            <PopoverArrow />

            <PopoverBody>
              <VStack
                align="start"
                spacing={1}
                fontSize={"11px"}
                borderColor="transparent"
                fontWeight="700"
                color={theme.colors.brand[400]}
              >
                {/* <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  w="100%"
                  p="1"
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    //   handleactive()
                    navigate("/create-Clientbasket");
                    closePopover(3);
                  }}
                >
                  {" "}
                  Add Clients Basket
                </Text>
                <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  w="100%"
                  p="1"
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    //   handleactive()
                    navigate("/Userbasket");
                    closePopover(3);
                  }}
                >
                  {" "}
                  View Clients Basket
                </Text> */}
                {/* <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  w="100%"
                  p="1"
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    onOpen();
                    closePopover(3);
                  }}
                >
                  {" "}
                  Add Clients
                </Text> */}
                <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  w="100%"
                  p="1"
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    //   handleactive()
                    navigate("/clients", { state: { api_status: "active" } });
                    closePopover(3);
                  }}
                >
                  {" "}
                  Active/Paused Clients
                </Text>
                {/* <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  w="100%"
                  p="1"
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/clients", { state: { api_status: "pause" } });
                    closePopover(3);
                  }}
                >
                  Paused Clients
                </Text> */}
                {/* <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  w="100%"
                  p="1"
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/clients", { state: { api_status: "discard" } });
                    closePopover(3);
                  }}
                >
                  Discard Clients
                </Text> */}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <Popover
          placement="right"
          onClose={() => closePopover(1)}
          isOpen={isPopoverOpen}
        >
          <PopoverTrigger>
            <Box
              w="100%"
              h="65px"
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              justifyContent={"center"}
              cursor={"pointer"}
              borderRadius={isHovered4 ? "10px" : ""}
              color={isHovered4 ? brand : brand}
              bg={isHovered4 ? "brand.900" : ""}
              onMouseOver={() => setIsHovered4(true)}
              onMouseOut={() => setIsHovered4(false)}
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            >
              <BasketIcon
                color={isHovered4 ? brand : brand}
                height={"27px"}
                width="27px"
              />

              <Text
                mt="1"
                borderColor="transparent"
                fontWeight="700"
                fontSize="13px"
              >
                Strategy
              </Text>
            </Box>
          </PopoverTrigger>

          <PopoverContent w="170px" bg="white">
            <PopoverArrow />

            <PopoverBody>
              <VStack
                align="start"
                spacing={1}
                fontSize={"11px"}
                borderColor="transparent"
                fontWeight="700"
              >
                <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  color={theme.colors.brand[400]}
                  w="90%"
                  p="1"
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/my-strategy");
                    closePopover(1);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 6 4"
                    fill="none"
                  >
                    <ellipse
                      cx="3.03001"
                      cy="2.0013"
                      rx="2.47899"
                      ry="2.0013"
                      fill={theme.colors.brand[400]}
                    />
                    <path
                      d="M0.0265823 2.05823L0.907056 0.972375L0.953198 3.09748L0.0265823 2.05823Z"
                      fill={theme.colors.brand[400]}
                    />
                    <path
                      d="M5.99164 2.0397L5.07728 3.09176L5.09852 0.966188L5.99164 2.0397Z"
                      fill={theme.colors.brand[400]}
                    />
                    <ellipse
                      cx="2.85892"
                      cy="1.80052"
                      rx="0.857701"
                      ry="0.800521"
                      fill="white"
                    />
                  </svg>
                  View Strategy
                </Text>
                <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  color={theme.colors.brand[400]}
                  w="100%"
                  p="1"
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/create-strategy");
                    closePopover(1);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 7 7"
                    fill="none"
                  >
                    <path
                      d="M3.46826 0.757812V5.56948"
                      stroke={theme.colors.brand[400]}
                      stroke-linecap="round"
                    />
                    <path
                      d="M1 3.24219L5.78215 3.28289"
                      stroke={theme.colors.brand[400]}
                      stroke-linecap="round"
                    />
                  </svg>
                  Create Strategy
                </Text>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <Popover
          placement="right"
          onClose={() => closePopover(2)}
          isOpen={isPopoverOpen2}
        >
          <PopoverTrigger>
            {/* <IconButton
                icon={<PortfolioIcon height={"27px"} width="27px" />}
                aria-label="Open menu"
                bg="none"
                onClick={() => setIsPopoverOpen2(!isPopoverOpen2)}
              /> */}
            <Box
              w="100%"
              h="65px"
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              justifyContent={"center"}
              cursor={"pointer"}
              borderRadius={isHovered5 ? "10px" : ""}
              color={isHovered5 ? brand : brand}
              bg={isHovered5 ? "brand.900" : ""}
              onMouseOver={() => setIsHovered5(true)}
              onMouseOut={() => setIsHovered5(false)}
              onClick={() => setIsPopoverOpen2(!isPopoverOpen2)}
            >
              <PortfolioIcon
                color={isHovered5 ? brand : brand}
                height={"27px"}
                width="27px"
              />

              <Text
                mt="1"
                fontSize="13px"
                borderColor="transparent"
                fontWeight="700"
              >
                Portfolio
              </Text>
            </Box>
          </PopoverTrigger>

          <PopoverContent w="190px" bg="white">
            <PopoverArrow />

            <PopoverBody>
              <VStack
                align="start"
                spacing={1}
                fontSize={"11px"}
                borderColor="transparent"
                fontWeight="700"
              >
                <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  color={theme.colors.brand[400]}
                  w="75%"
                  p="1"
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/my-portfolio");
                    closePopover(2);
                  }}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 6 4"
                    fill="none"
                  >
                    <ellipse
                      cx="3.03001"
                      cy="2.0013"
                      rx="2.47899"
                      ry="2.0013"
                      fill={theme.colors.brand[400]}
                    />
                    <path
                      d="M0.0265823 2.05823L0.907056 0.972375L0.953198 3.09748L0.0265823 2.05823Z"
                      fill={theme.colors.brand[400]}
                    />
                    <path
                      d="M5.99164 2.0397L5.07728 3.09176L5.09852 0.966188L5.99164 2.0397Z"
                      fill={theme.colors.brand[400]}
                    />
                    <ellipse
                      cx="2.85892"
                      cy="1.80052"
                      rx="0.857701"
                      ry="0.800521"
                      fill="white"
                    />
                  </svg>
                  View Portfolio
                </Text>
                <Text
                  margin={"0px"}
                  fontSize="13px"
                  borderRadius={4}
                  color={theme.colors.brand[400]}
                  w="85%"
                  p="1"
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  _hover={{ background: "#e6ebf4", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/create-portfolio");
                    closePopover(2);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 7 7"
                    fill="none"
                  >
                    <path
                      d="M3.46826 0.757812V5.56948"
                      stroke={theme.colors.brand[400]}
                      stroke-linecap="round"
                    />
                    <path
                      d="M1 3.24219L5.78215 3.28289"
                      stroke={theme.colors.brand[400]}
                      stroke-linecap="round"
                    />
                  </svg>
                  Create Portfolio
                </Text>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </VStack>
      {isOpen && <AddClient isOpen={isOpen} onClose={onClose} />}
      {openModal && (
        <CreateStrategy
          isOpen={openModal}
          onClose={() => dispatch(toggleModal())}
          onOpen={onOpenUpload}
        />
      )}
      {isOpenUpload && (
        <CSVUpload isOpen={isOpenUpload} onClose={onCloseUpload} />
      )}
    </>
  );
};

export default Sidebar;
