

import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import FormSelect from "components/controls/FormSelect";
import { useEffect, useState } from "react";
import {
  getAccestokenPl,
  getClientNewlist,
  getClientNewlistNew,
  getClientsListPL,
} from "apis/client";
import { useLocation } from "react-router-dom";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import DownloadClientData from "./DownloadClientData";
import { useForm, FormProvider } from "react-hook-form";
import moment from "moment";
import CustomizeSummary from "./CustomizeSummary";
import Stomp from "stompjs";
import FormButton from "components/controls/Button";
import CustomForm from "utils/useForm";

const SummaryFilter = (props) => {
  const { active } = props;
 
  const methods = useForm();
  const [message, setMessage] = useState("");
  const [clientEmails, setClientEmails] = useState([]);
  const [clientslist, setClientsList] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [filteredclientslist, setFilteredClientsList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({});
  const [text, setText] = useState("");
  const [selectedValue, setSelectedValue] = useState("10");
  const [selectedDates, setSelectedDates] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [formatdate, setFormatdate] = useState({});
  const [stompClient, setStompClient] = useState(null);
  const locationState = useLocation();
  const [liveoveralldata, setLiveoveralldata] = useState(0);
  const [liveoveralldata2, setLiveoveralldata2] = useState(0);
  const { location } = locationState.state || {};
  const [date, setDate] = useState("");
  const [overallrealized, setOverallrealized] = useState(0);
  const [clients, setClients] = useState([]);
  const [chooseClient, setChooseClient] = useState("");
  function customSort(a, b) {
    // Move objects with non-empty positions to the top
    if (a.positions.length > 0 && b.positions.length === 0) {
      return -1; // a comes before b
    } else if (a.positions.length === 0 && b.positions.length > 0) {
      return 1; // b comes before a
    }
    return 0; // no change in order
  }

  function removeDuplicates(arrayWithDuplicates) {
    const uniqueSet = new Set(arrayWithDuplicates?.map(JSON.stringify));
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    return uniqueArray;
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        client_id: chooseClient || "",
        client_type: active,
      };
      const res = await getClientsListPL(page, selectedValue, data);

      if (res?.data[0]?.pnl !== null) {
        const sortedData = res?.data[0]?.pnl?.sort(customSort);
        if (locationState?.state?.api_status !== "clients") {
          connectRabbit(sortedData);
          setOverallrealized(res?.data[0]?.overall_realized_pnl);
        }

        setPageData(res?.pagination);
        setClientsList(sortedData);
        setAllClients(sortedData);
      }
    };

    fetchData();

    return () => {
      // Disconnect the previous connection when the component unmounts
      if (stompClient) {
        stompClient.disconnect();
      }
    };
  }, [page, selectedValue, chooseClient]);

  useEffect(() => {
    return () => {
      if (stompClient) {
        stompClient.disconnect();
      }
    };
  }, [stompClient]);

  const connectRabbit = (arr) => {
    if (stompClient) {
      stompClient.disconnect();
    }

    let newStompClient;
    const ws = new WebSocket(
      "wss://merry-khaki-snake-01.rmq2.cloudamqp.com/ws/"
    );
    const headers = {
      login: "lxtysqng",
      passcode: "Pf9W8WyScu5qNXxQfoFA2xiAd_Vz3g-M",
      durable: "true",
      "auto-delete": "false",
      host: "lxtysqng",
      // heart-beat : 0,0
    };
    const destinationToLastPriceMap = new Map();

    newStompClient = Stomp.over(ws);
    newStompClient.debug = null;

    newStompClient.connect(headers, function (frame) {
      const destinationGroups = {};

      arr.forEach((e, i) => {
        if (e.positions !== null) {
          e.positions.forEach((ele, ind) => {
            if (ele.buy_or_sell !== "") {
              let destination;
              if (ele.exchange === "NSE") {
                destination = `/exchange/NSE/${ele.symbol}.${ele.symbol}`;
              } else if (ele.right === "FUT") {
                destination = `/exchange/NFO/${ele?.symbol}.${ele?.datafeed_symbol}`;
              } else {
                destination = `/exchange/NFO/${ele?.symbol}.${ele?.datafeed_symbol}`;
              }

              if (!destinationGroups[destination]) {
                destinationGroups[destination] = {
                  objects: [],
                  subscription: null,
                };
              }

              destinationGroups[destination].objects.push({
                obj: e,
                index: i,
                innerIndex: ind,
              });
            }
          });
        }
      });

      for (const [destination, group] of Object.entries(destinationGroups)) {
        if (!destinationToLastPriceMap.has(destination)) {
          destinationToLastPriceMap.set(destination, null);

          group.subscription = newStompClient.subscribe(
            destination,
            function (message) {
              const temp = JSON.parse(message.body);
              destinationToLastPriceMap.set(destination, temp.last_price);

              group.objects.forEach((objInfo) => {
                const { obj, index, innerIndex } = objInfo;
                const buy_or_sell = obj.positions[innerIndex].buy_or_sell;
                const avg_price = obj.positions[innerIndex].avg_price;
                const net_quantity = obj.positions[innerIndex].net_quantity;
                const lastPrice = destinationToLastPriceMap.get(destination);

                obj.positions[innerIndex].unrealized_pnl =
                  obj.positions[innerIndex].producttype !== "DELIVERY"
                    ? buy_or_sell === "BUY"
                      ? Number((lastPrice - avg_price) * Math.abs(net_quantity))
                      : buy_or_sell === "SELL"
                      ? Number((avg_price - lastPrice) * Math.abs(net_quantity))
                      : 0.0
                    : buy_or_sell === "BUY"
                    ? Number((lastPrice - avg_price) * Math.abs(net_quantity))
                    : buy_or_sell === "SELL"
                    ? Number((avg_price - lastPrice) * Math.abs(net_quantity))
                    : 0.0;

                obj.unrealized_pnl = obj.positions.reduce(
                  (accumulator, currentObj) =>
                    accumulator + parseFloat(currentObj.unrealized_pnl),
                  0
                );

                setClientsList((prev) => {
                  const newList = [...prev];
                  newList[index] = { ...obj };
                  return newList;
                });
              });
            }
          );
        }
      }
    });

    setStompClient(newStompClient);
  };

  const onsearchClient = (e) => {
    let value = e.target.value.replace(/ /g, "");
    setMessage(value);
  };

  const reloadTableData = async () => {
    window.location.reload();
  };

  const handlePaginationChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    handleclientlists();
  }, [text]);
  const handleclientlists = async () => {
    
    const res = await getClientNewlistNew(page, selectedValue, active, text);

    setClients(
      res?.data?.map((item, i) => ({
        label: item?.client_id,
        value: item?.client_id_id,
      }))
    );
  };

  return (
    <Box>
      <CustomForm methods={methods} onSubmit={methods.handleSubmit()}>
        <Grid templateColumns="repeat(3, 1fr)" mb="2">
          <GridItem alignItems={"center"}>
            <Text fontWeight={"bold"}>Client P&L statement </Text>
          </GridItem>
          <GridItem></GridItem>
          <GridItem>
            <Flex align="right" width="100%" gap="4">
              <FormSelect
                name="email"
                placeholder="Clients Code"
                control={methods.control}
                error={methods.formState.errors}
                options={clients || []}
                width="100%"
                onChangeNew={(e) => {
                  setChooseClient(e.label);
                }}
                onInputChange={(e) => setText(e)}
              />
              {/* <FormButton
            text="Apply"
            onClick={() => {
              // setIsApplyLoading(true); // Set loading state to true
              // onAddClient();
            }}
            // isLoading={isApplyLoading}
            // loadingText="Applying..."
          /> */}
              <FormButton
                text="Reset"
                onClick={() => {
                  window.location.reload();
                  // setIsApplyLoading(true); // Set loading state to true
                  // onAddClient();
                }}
                // isLoading={isApplyLoading}
                // loadingText="Applying..."
              />
              {/* <FormButton
               w="35%"
                text="Download" /> */}
            </Flex>
            
          </GridItem>
          {/* <GridItem>
        <Flex>
           <FormButton
            text="Apply"
            onClick={() => {
              // setIsApplyLoading(true); // Set loading state to true
              // onAddClient();
            }}
            // isLoading={isApplyLoading}
            // loadingText="Applying..."
          />
          <FormButton
            text="Reset"
            onClick={() => {
              // setIsApplyLoading(true); // Set loading state to true
              // onAddClient();
            }}
            // isLoading={isApplyLoading}
            // loadingText="Applying..."
          />
        </Flex>
      </GridItem> */}

          {/* Other GridItems can be added here */}
        </Grid>
      </CustomForm>

      <CustomizeSummary
        handlePaginationChange={handlePaginationChange}
        page={page}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        setPage={setPage}
        pageData={pageData}
        setPageData={setPageData}
        clientslist={clientslist}
        setClientEmails={setClientEmails}
        allClients={allClients}
        condition={locationState?.state?.api_status}
      />
    </Box>
  );
};

export default SummaryFilter;

