import { useState, useEffect } from "react";

import {
  Checkbox,
  HStack,
  IconButton,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Tr,
  Text,
  useDisclosure,
  Thead,
  TableContainer,
  Box,
  Select,
} from "@chakra-ui/react";
import {
  // ... your other imports
  BsFillArrowDownCircleFill, // Scroll to Top icon
  BsFillArrowUpCircleFill, // Scroll to Bottom icon
} from "react-icons/bs";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { FormProvider, useForm } from "react-hook-form";
import FormSelect from "components/controls/FormSelect";
import FormButton from "components/controls/Button";
import { useNavigate } from "react-router-dom";
import ClientPandL from "components/modals/ClientPandL";
import UserPL from "components/modals/UserPL";

const CustomizeSummary = (props) => {
  const {
    clientslist,
    page,
    setPage,
    pageData,
    setPageData,
    setClientEmails,
    allClients,
    condition,
    selectedValue,setSelectedValue,handlePaginationChange
  } = props;
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };



  const [isCompletedOrderModal, setIsCompletedOrderModal] = useState(false);
  const [modaldata, setModaldata] = useState([]);
  const [userId, setUserId] = useState("");
  const { onClose } = useDisclosure();
  const [headindconditionally, setHeadindconditionally] = useState([]);
  const [headindconditionally2, setHeadindconditionally2] = useState([]);

  let secondary_color = localStorage.getItem("secondary_color");
  const navigate = useNavigate();
  const [selectedClientEmails, setSelectedClientEmails] = useState([]);
  const mainHeading = ["Clients Code", "Today", "", "Earning Graph"];

  const [unrealizedSortAscending, setUnrealizedSortAscending] = useState(true);
  const [sumSortAscending, setSumSortAscending] = useState(true);

  const [sortedClientsList, setSortedClientsList] = useState(clientslist);
  const heading = [
    "Completed Orders",
    "Rejected ",
    "Realized P&L",
    "Unrealized P&L",
    "Total P&L",
  ];
 
  // const toggleSortOrder = () => {
  //   setAscendingSort(!ascendingSort);
  // };
  

  const toggleUnrealizedSortOrder = () => {
    setUnrealizedSortAscending(!unrealizedSortAscending);
  };

  const toggleSumSortOrder = () => {
    setSumSortAscending(!sumSortAscending);
  };

  useEffect(() => {
    if (Array.isArray(clientslist)) {
      let sortedList = [...clientslist];

      // Custom sorting function
      const customSortFunction = (a, b) => {
        const unrealizedA = a.unrealized_pnl;
        const unrealizedB = b.unrealized_pnl;
        const sumA = a.realized_pnl + a.unrealized_pnl;
        const sumB = b.realized_pnl + b.unrealized_pnl;

       
      };

      if (!sumSortAscending) {
        sortedList.sort((a, b) => {
          const sumA = a.realized_pnl + a.unrealized_pnl;
          const sumB = b.realized_pnl + b.unrealized_pnl;

          if (sumA === 0 && sumB === 0) {
            return 0; // Maintain original order for items with both sums zero
          } else if (sumA === 0) {
            return 1; // Move items with sumA zero to the bottom
          } else if (sumB === 0) {
            return -1; // Move items with sumB zero to the bottom
          } else {
            return sumA - sumB;
          }
        });
      } else {
        sortedList.sort((a, b) => {
          const sumA = a.realized_pnl + a.unrealized_pnl;
          const sumB = b.realized_pnl + b.unrealized_pnl;

          if (sumA === 0 && sumB === 0) {
            return 0; // Maintain original order for items with both sums zero
          } else if (sumA === 0) {
            return 1; // Move items with sumA zero to the bottom
          } else if (sumB === 0) {
            return -1; // Move items with sumB zero to the bottom
          } else {
            return sumB - sumA;
          }
        });
      }
      const updatedDataArray = sortedList.map((item) => ({
        ...item,
        unrealized_totalpl: isNaN(item.unrealized_totalpl)
          ? 0
          : item.unrealized_totalpl,
      }));

      setSortedClientsList(updatedDataArray);
    }
  }, [unrealizedSortAscending, sumSortAscending, clientslist]);

  const mainHeading2 = ["Clients Code", "Today", "Overall", "Earning Graph"];
  const heading2 = [
    "Completed ",
    "Rejected",
    "Realized P&L",
    "Completed ",
    "Rejected ",
    "Realized P&L",
    "Total P&L",
  ];
  const methods = useForm();

  const options = [...Array(pageData?.total_pages)].map((_, index) => ({
    label: index + 1,
    value: index + 1,
  }));

  // Function to toggle the expansion of a row

  useEffect(() => {
    setClientEmails(selectedClientEmails);

    if (condition === "clients") {
      setHeadindconditionally(mainHeading2);
      setHeadindconditionally2(heading2);
    } else {
      setHeadindconditionally(mainHeading);
      // setHeadindconditionally2(heading);
    }
  }, [selectedClientEmails]);

  // Pagination logic

  const onPageChange = async (page) => {
    try {
      setPage(page);
      setPageData({ ...pageData, page });
    } catch (error) {
      console.error(error);
    }
  };

  // add client emails to setClientEmails state
  // setClientEmails(selectedClientEmails);

  // checkbox logic
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedClientEmails([...selectedClientEmails, value]);
    } else {
      setSelectedClientEmails(
        selectedClientEmails.filter((email) => email !== value)
      );
    }
  };

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      const allClientEmails = allClients?.map((client) => client.client_id);
      setSelectedClientEmails(allClientEmails);
    } else {
      setSelectedClientEmails([]);
    }
  };

  let isAllClientsSelected;
  if (allClients !== undefined) {
    isAllClientsSelected = selectedClientEmails?.length === allClients?.length;
  }

  return (
    <Box w="100%">
      <TableContainer
        borderRadius={"4px"}
        size="sm"
        h="51vh"
        overflowY={"auto"}
      >
        <Table variant="custom">

          <Thead position={"sticky"} top="0" zIndex={5} bg="brand.700">
              <TableCaption
              position={"fixed"}
              w="100%"
              h="50px"
              bg="#F3F7FE"
              float={"right"}
              bottom={0}
              right={0}
            >
              <HStack float="right" h="100%">
                <HStack gap={8}>
                  <Text fontWeight="700">
                    Page {page} of {pageData?.total_pages  || 1}
                  </Text>
                  <HStack width={60}>
                    <Text fontWeight="700">Go To Page</Text>
                    <FormProvider {...methods}>
                      <FormSelect
                        control={methods.control}
                        error={methods.formState.errors}
                        name="page_handler"
                        onChangeNew={(e) => handlePaginationChange(e.value)}
                        options={options}
                        size="sm"
                        w="30px"
                      />
                    </FormProvider>
                  </HStack>
                </HStack>

                <HStack gap={1} h="100%">
                  <Select
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </Select>
                  <IconButton
                    fontWeight="700"
                    onClick={() => handlePaginationChange(page !== 1 ? page - 1 : 1)}
                    isDisabled={pageData?.first}
                    icon={<ChevronLeftIcon h="6" w="6" />}
                  />
                  <IconButton
                    // color={textColor}
                    fontWeight="700"
                    onClick={() =>
                      handlePaginationChange(
                        page !== pageData?.total_pages ||
                          page < pageData?.total_pages
                          ? page + 1
                          : pageData?.total_pages
                      )
                    }
                    isDisabled={pageData?.last || pageData?.total_pages===0}
                    icon={<ChevronRightIcon h="6" w="6" />}
                  />
                </HStack>
              </HStack>
            </TableCaption>
            <Tr bg={"brand.900"}>
              {headindconditionally?.map((item, index) => {
                return (
                  <Th
                    fontWeight="700"
                    key={index}
                    colSpan={item === "Today" || item === "Overall" ? 4 : ""}
                    // border="1px solid grey"
                    textAlign={
                      item === "Today" || item === "Overall" ? "center" : "left"
                    }
                    fontSize={"14px"}
                    color={"brand.500"}
                    borderRight="2px solid white"
                    borderTopLeftRadius={index === 0 && "15px"}
                  >
                    {item}
                  </Th>
                );
              })}
            </Tr>
            <Tr>
              <Th bg="brand.700" fontWeight="700">
                {/* <Checkbox
                sx={{ borderColor: "green" }}
                colorScheme="green"
                isChecked={isAllClientsSelected}
                onChange={handleSelectAllChange}
              >
                <Text pl={2}  fontWeight="700" fontSize={"13px"}>
                  Select All 
                </Text>
              </Checkbox> */}
                <Text pl={2} fontWeight="700" fontSize={"13px"}>
                  Client Id
                </Text>
              </Th>
              {condition === "clients" ? (
                headindconditionally2?.map((item, index) => {
                  return (
                    <Th
                      bg="brand.700"
                      key={index}
                      whiteSpace="nowrap"
                      fontWeight="700"
                      fontSize={"13px"}
                    >
                      {item}
                    </Th>
                  );
                })
              ) : (
                <>
                  <Th
                    bg="brand.700"
                    fontWeight="700"
                    p={0}
                    margin={0}
                    whiteSpace={"nowrap"}
                    fontSize={"13px"}
                  >
                    <Text textAlign={"right"}>Completed </Text>
                  </Th>
                  <Th bg="brand.700" fontSize={"13px"} whiteSpace="nowrap">
                    <Text textAlign={"right"}>Rejected </Text>
                  </Th>
                  <Th bg="brand.700" fontSize={"13px"} whiteSpace="nowrap">
                    <Text textAlign={"right"}>Realized P&L</Text>
                  </Th>
                  <Th bg="brand.700" fontSize={"13px"} whiteSpace="nowrap">
                    {/* <HStack> */}
                    <Text textAlign={"right"}>Unrealized P&L</Text>

                    {/* </HStack> */}
                  </Th>

                  <Th bg="brand.700" fontSize={"13px"} whiteSpace="nowrap">
                    <HStack
                      w="100%"
                      justifyContent="flex-end"
                      alignItems="center"
                     
                    >
                      <Text textAlign={"right"}>Total P&L</Text>
                      {!sumSortAscending ? (
                        <AiOutlineArrowUp
                          cursor="pointer"
                          fontSize="20px"
                          onClick={toggleSumSortOrder}
                        />
                      ) : (
                        <AiOutlineArrowDown
                          cursor="pointer"
                          fontSize="20px"
                          onClick={toggleSumSortOrder}
                        />
                      )}
                    </HStack>
                  </Th>

                  {/* <Th bg="brand.700"></Th> */}
                  <Th bg="brand.700" whiteSpace="nowrap"></Th>
                </>
              )}
            </Tr>
          </Thead>
          <Tbody bg="white" overflow={"auto"}>
            {Array.isArray(sortedClientsList) &&
            sortedClientsList.length > 0 ? (
              sortedClientsList?.map((item, index) => {
                return (
                  <>
                    <Tr key={item.id} fontWeight={600}>
                      <Td>
                        {/* <Checkbox
                        sx={{ borderColor: "gray" }}
                        colorScheme="green"
                        isChecked={selectedClientEmails.includes(
                          item?.client_id
                        )}
                        onChange={handleCheckboxChange}
                        value={item?.client_id}
                      >
                    </Checkbox> */}
                        <Text>{item?.client_id}</Text>
                      </Td>
                      <Td textAlign="right">
                        {item?.completed?.toLocaleString("en-IN")}
                      </Td>
                      <Td textAlign="right">
                        {item?.rejected?.toLocaleString("en-IN")}
                      </Td>
                      <Td textAlign="right">
                        <Text
                          color={
                            item?.realized_pnl < 0
                              ? "red"
                              : item?.realized_pnl > 0
                              ? "green"
                              : "black"
                          }
                        >
                          {item?.realized_pnl === ""
                            ? Number(0)?.toFixed(2)
                            : Number(item?.realized_pnl)?.toLocaleString(
                                "en-IN",
                                {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }
                              )}
                        </Text>
                      </Td>
                      {condition === "clients" ? (
                        <Td>{item?.overall_total_completed}</Td>
                      ) : (
                        <Td>
                          <Text
                            textAlign="right"
                            color={
                              item?.unrealized_pnl < 0
                                ? "red"
                                : item?.unrealized_pnl > 0
                                ? "green"
                                : "black"
                            }
                          >
                            {item?.unrealized_pnl?.toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                          </Text>
                        </Td>
                      )}
                      {condition === "clients" ? (
                        <Td>{item?.overall_error_or_rejected}</Td>
                      ) : (
                        ""
                      )}
                      {condition === "clients" ? (
                        <Td
                          textAlign="right"
                          color={
                            item?.overall_realized_pl < 0
                              ? "red"
                              : item?.overall_realized_pl > 0
                              ? "green"
                              : "black"
                          }
                        >
                          {item?.overall_realized_pl?.toFixed(2)}
                        </Td>
                      ) : (
                        <></>
                      )}
                      <Td
                        textAlign="right"
                        color={
                          Number(item?.realized_pnl + item?.unrealized_pnl) > 0
                            ? "green"
                            : Number(
                                item?.realized_pnl + item?.unrealized_pnl
                              ) < 0
                            ? "red"
                            : "black"
                        }
                      >
                        {Number(
                          item?.realized_pnl + item?.unrealized_pnl
                        )?.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </Td>

                      {condition === "clients" ? <Td></Td> : <></>}
                      {/* {condition !== "clients" ? (
                      <Td   >
                        <FormButton
                          height={"35px"}
                          text={"View"}
                          onClick={() => {
                            setIsCompletedOrderModal(true);
                            setModaldata(item?.positions);
                            setUserId(item?.client_id)
                          }}
                        />
                      </Td>
                    ) : (
                      <></>
                    )} */}
                      <Td>
                        <FormButton
                          height={"35px"}
                          text="Report"
                          w="70%"
                          margin="auto"
                          onClick={() => {
                            navigate("/summary-graph", {
                              state: {
                                email: item?.client_id,

                                name: "client",
                                client_name: item?.client_id,
                              },
                              item,
                            });
                          }}
                        />
                      </Td>
                      {/* setIsCompletedOrderModal(true); */}
                    </Tr>
                  </>
                );
              })
            ) : (
              <Tr>
                <Td p={3} colSpan={8} textAlign="center">
                  No Data Available
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <UserPL
        setIsCompletedOrderModal={setIsCompletedOrderModal}
        isOpen={isCompletedOrderModal}
        onClose={onClose}
        modaldata={modaldata}
        userId={userId}
      />
    </Box>
  );
};

export default CustomizeSummary;
